import { firebaseAuth } from '../deps.js'
const { onAuthStateChanged, signInWithCustomToken } = firebaseAuth

import { getDeviceId, acequiaReady } from '../acequia/acequia.js'

import utils from '../acequia/utils.js'

import {
    child,
    root,
    auth,
    ref,
    refToPromise,
    rootRef,
    set,
    update,
    serverTimestamp,
} from '../acequia/firebase.js'

import { jose } from '../deps.js'
const { exportJWK, SignJWT } = jose

export { onAuthStateChanged, auth }

let resolveReady
let readyPromise = new Promise((resolve) => {
    resolveReady = resolve
})

onAuthStateChanged(auth, async (user) => {
    let { userId, keys } = await acequiaReady()

    console.log('user changed: ', userId, keys)

    resolveReady(user)
})

export async function ensurePublicKeyPublished(userId, keys) {
    let currentKey = await refToPromise(ref(root, `/auth/${userId}/public`))

    console.log('currentKey: ', currentKey)

    let localCurrentKey = await exportJWK(keys.publicKey)

    if (!currentKey || currentKey.n !== localCurrentKey.n) {
        console.log('publishing new key')
        const publicJWK = await exportJWK(keys.publicKey)
        await update(ref(root, `/auth/${userId}/public`), publicJWK)
    }
}

export async function getPublicKey(userId) {
    const jwk = await (
        await fetch(`https://acequia.firebaseio.com/auth/${userId}/public.json`)
    ).json()
    return jose.importJWK(jwk, 'PS256')
}

export async function publishToken(token, idLength = 6) {
    let payload = jose.decodeJwt(token)
    const tokenId = utils.createId().substring(0, idLength)
    await set(child(rootRef, `tokens/${tokenId}`), {
        token,
        exp: payload.exp || 'none',
    })
    return tokenId
}

export function authReady() {
    return readyPromise
}

export function firebaseUser() {
    return auth.currentUser
}

export async function signIn() {
    if (!auth.currentUser) {
        let { deviceId, userId, instanceId, keys, privateJWK } =
            await acequiaReady()

        let token = await new SignJWT({ deviceId, userId, instanceId })
            .setSubject(userId)
            .setIssuedAt()
            .setIssuer(location.hostname)
            .setProtectedHeader({
                alg: 'PS256',
                kid: privateJWK.kid,
            })
            .sign(keys.privateKey)

        let response = await fetch(
            `https://auth.acequia.io/firebase?uid=${userId}&deviceId=${deviceId}&token=${token}`
        )

        let firebaseToken = await response.json()
        if (!firebaseToken.error) {
            console.log('got firebase token: ', firebaseToken)

            await signInWithCustomToken(auth, firebaseToken.firebaseToken)
            await update(ref(root, `/scripts/users/${userId}/lastLogin`), {
                deviceId: getDeviceId(),
                timestamp: serverTimestamp(),
            })
        } else {
            console.error('error:', firebaseToken)
            console.log('failed token: ', token)
        }
    } else {
        console.log('-- user: ', auth.currentUser)
    }
}

export function signOut() {
    auth.signOut()
}

if (typeof window !== 'undefined') window.auth = auth
