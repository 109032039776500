import {
    P2PServer,
    P2PClient,
} from 'https://redfishgroup.github.io/firebase_webrtc_handshake/dist/build.nofirebase.js'

import * as firebase from './firebase.js'

const { child } = firebase

let server = null
let clients = {}

async function getPeerListener(
    name = 'peer-view',
    instanceID = null,
    peersRef,
    options = {},
    initialPeerInfo = {}
) {
    if (!server) {
        const iceServers = await getICEservers()

        if (instanceID) initialPeerInfo.instanceID = instanceID
        initialPeerInfo.id = `${name}-${initialPeerInfo.instanceID}`

        server = new P2PServer(
            {
                firebase,
                PER_CHUNK_WAIT: 0,
                POLLING_FREQUENCY: 60000,
                id: initialPeerInfo.id,
                database: child(peersRef, 'peers'),
                treeTrimmingRef: child(peersRef, 'treeTrimming'),
                iceServers,
                ...options,
            },
            initialPeerInfo
        )

        server.peerInfo = initialPeerInfo
    }

    return server
}

function getClient(options = {}) {
    const clientOptions = {
        PER_CHUNK_WAIT: 0,
        ...options,
    }

    if (clients[clientOptions.peerID]) return clients[clientOptions.peerID]

    const client = new P2PClient(clientOptions)

    console.log('creating client: ', clientOptions)

    if (clientOptions.serverID) {
        client.connectionReady = new Promise((resolve, reject) => {
            client.connectToPeerID(
                clientOptions.peerID,
                function (err, connection) {
                    if (err) {
                        console.error(
                            'Got an error connecting to peer:',
                            clientOptions.peerID,
                            err
                        )
                        return reject(err)
                    }

                    console.log(`client ${client.id} connected`)
                    resolve(client)

                    client.sendAck('hello world', (data) => {
                        console.log('got ack back! :)', data, client.id)
                    })

                    connection.on('close', function (data) {
                        console.log(
                            `client: ${client.id} closed.  Removing client.`
                        )
                        delete clients[client.peerID]
                    })

                    client.getPeerList(function (err, val) {
                        var count = Object.keys(val).length
                        console.log(`Client sees ${count} peers `)
                        console.assert(!err, err)
                        console.assert(
                            count > 0,
                            "No servers. That shouldn't happen"
                        )
                    })
                }
            )
        })
    }

    clients[clientOptions.peerID] = client
    return client
}

// convert 'url' to 'urls'
function urlToUrls(iceServers) {
    return iceServers.map(function (b) {
        let res = {}
        for (var i in b) {
            if (i === 'url') res.urls = b[i]
            else res[i] = b[i]
        }
        return res
    })
}

// try one server then the other
export function getICEservers() {
    return new Promise((resolve2, reject2) => {
        getTwilioICEservers()
            .then((val1) => {
                console.warn('got twilio')
                resolve2(val1)
            })
            .catch((err1) => {
                console.error('no ice servers... ', err1)
                reject2(err1)
            })
    })
}

export function getXirsysICEservers(callback) {
    return new Promise(function (resolve, reject) {
        const req = new XMLHttpRequest()
        const url = 'https://service.xirsys.com/ice'
        const params = JSON.stringify({
            ident: 'simtable',
            secret: '0099daf6-4486-11e6-acfb-2b5d0b49b1ef',
            domain: 'www.livetexture.com',
            application: 'default',
            room: 'default',
            secure: 1,
        })
        req.timeout = 4000
        req.open('POST', url, true)
        req.setRequestHeader('Content-type', 'application/json; charset=utf-8')
        req.onreadystatechange = function () {
            if (req.readyState === 4) {
                if (req.status === 200) {
                    const response = JSON.parse(req.responseText)
                    if (response.s && response.s !== 200) {
                        reject(response)
                        if (callback) callback(response)
                        return
                    }

                    var resp2 = urlToUrls(response.d.iceServers)
                    if (callback) callback(undefined, resp2)
                    resolve(resp2)
                }
            }
        }
        req.addEventListener('error', (err) => {
            reject(err)
            if (callback) callback(err)
        })
        req.addEventListener('abort', (err) => {
            reject(err)
            if (callback) callback(err)
        })
        req.ontimeout = (err) => {
            reject(err)
            if (callback) callback(err)
        }
        req.send(params)
    })
}

export function getTwilioICEservers(callback) {
    return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest()
        const url =
            'https://api.twilio.com/2010-04-01/Accounts/ACc732104ae0a5e1317c8cb5f9fe98a08d/Tokens.json'
        req.timeout = 4000
        req.open('POST', url, true)
        req.setRequestHeader(
            'Authorization',
            'Basic ' +
                btoa(
                    'ACc732104ae0a5e1317c8cb5f9fe98a08d:383d616592ca2fd3948537f21cd31619'
                )
        )
        req.setRequestHeader('Content-type', 'application/json; charset=utf-8')
        req.onreadystatechange = function () {
            if (req.readyState === 4) {
                if (req.status >= 200 && req.status <= 210) {
                    const response = JSON.parse(req.responseText)
                    var resp2 = urlToUrls(response.ice_servers)
                    if (callback) callback(undefined, resp2)
                    resolve(resp2)
                } else {
                    const err = req.responseText
                    reject(err)
                    if (callback) callback(err)
                }
            }
        }
        req.addEventListener('error', (err) => {
            reject(err)
            if (callback) callback(err)
        })
        req.addEventListener('abort', (err) => {
            reject(err)
            if (callback) callback(err)
        })
        req.ontimeout = (err) => {
            reject(err)
            if (callback) callback(err)
        }
        req.send()
    })
}

function getClients() {
    return clients
}

const webrtc = { getPeerListener, getClient, getClients }

export { webrtc }
