import { getPublicKey, publishToken, authReady } from './auth.js'
import { acequiaReady } from '../acequia/acequia.js'
import { jose } from '../deps.js'

export { jose }

export async function verifyToken(tokenJWT) {
    // assume token has userId in token payload signed by that user
    const token = jose.decodeJwt(tokenJWT)
    const { userId } = token
    const publicKey = await getPublicKey(userId)
    console.log(publicKey)

    return jose.jwtVerify(tokenJWT, publicKey)
}

export async function createToken(payload, expiresTime = 60 * 5) {
    let { userId, keys, privateJWK } = await acequiaReady()
    const newExpiresTime = Math.floor(Date.now() / 1000) + expiresTime

    return await new jose.SignJWT(payload)
        .setSubject(userId)
        .setIssuedAt()
        .setIssuer(location.hostname)
        .setProtectedHeader({
            alg: 'PS256',
            kid: privateJWK.kid,
        })
        .setExpirationTime(newExpiresTime)
        .sign(keys.privateKey)
}

export async function createInvite(groupId, type, redirect, expiresTime) {
    let { userId, deviceId } = await acequiaReady()

    const payload = {
        groupId,
        type,
        redirect,
        userId,
        deviceId,
    }
    const token = await createToken(payload, expiresTime)
    const tokenId = await publishToken(token)

    return tokenId
}
