import { Evented } from './evented.js'

import {
    child,
    onValue,
    rootRef,
    set,
    update,
    refToPromise,
} from './firebase.js'

import { getUserRef, getHandleRef, getAliasesRef } from './user.js'

import utils from './utils.js'

export const supportedTypes = {
    'text/plain': 'Text',
    'text/html': 'HTML',
    'text/css': 'CSS',
    'text/javascript': 'Javascript',
    'application/json': 'JSON',
    'text/markdown': 'Markdown',
    'application/yaml': 'yaml',
    'application/acequia.js': 'Acequia Script',
    'model/gltf-binary': 'GLTF',
}

export function getScriptDataRef(scriptId) {
    return child(rootRef, `scripts/data/${scriptId}`)
}

export function getScriptMetaRef(scriptId) {
    return child(rootRef, `scripts/meta/${scriptId}`)
}

export function getPublicIDListRef() {
    return child(rootRef, 'scripts/public')
}

export async function resolveHandleAlias(handle, path) {
    const uid = await refToPromise(getHandleRef(handle))
    if (!uid) throw new Error('handle not found')

    const aliases = await refToPromise(getAliasesRef(uid))
    if (!aliases) throw new Error('no aliases for user')

    for (let alias of aliases) {
        console.log(`${alias.alias} ??? ${path}`)
        let match = path.match(`^/?${alias.alias}/?(.*)`)

        if (match) {
            return { ...alias, match: match[1] }
        }
    }

    return null
}

export class Script extends Evented {
    constructor(scriptId, userId) {
        super()

        if (!scriptId || !userId)
            throw new Error('scriptId and userId must not be null')

        this.scriptId = scriptId
        this.userId = userId
        this.meta = {}
        this.data = ''
        this.readonly = true

        onValue(getScriptMetaRef(this.scriptId), async (snapshot) => {
            const val = snapshot.val()
            if (val === null) {
                // meta for this scriptId does not exist,  so claim it for this user
                let newMeta = {
                    scriptId: this.scriptId,
                    creator: userId,
                    lastupdate: Date.now(),
                    share: { [this.userId]: true },
                    public: false,
                    displayName: '',
                    type: 'text/plain',
                }
                await set(getScriptMetaRef(this.scriptId), newMeta)
                this.setUserShare()
                this.meta = newMeta
                this.fire('updatemeta')
            } else {
                this.meta = val
                this.fire('updatemeta')
            }
        })

        onValue(getScriptDataRef(this.scriptId), async (snapshot) => {
            const val = snapshot.val()
            if (val === null) {
                this.setData('')
            }
            this.data = val
            this.fire('updatedata')
        })
    }

    async setData(newValue) {
        console.log('writing to firebase')

        try {
            update(getScriptMetaRef(this.scriptId), { lastupdate: Date.now() })
            return await set(getScriptDataRef(this.scriptId), newValue)
        } catch (e) {
            console.warn('write failed: ', e)
        }
    }

    async setUserShare(scriptId) {
        scriptId = scriptId || this.scriptId
        set(child(getUserRef(this.userId), `/share/${scriptId}`), true)
    }

    async updateMeta(dict) {
        return update(getScriptMetaRef(this.scriptId), {
            ...dict,
            lastupdate: Date.now(),
        })
    }

    async togglePublic() {
        const newPublicState = !this.meta.public
        return Promise.all([
            this.updateMeta({ public: newPublicState }),
            update(getPublicIDListRef(), {
                [this.scriptId]: newPublicState ? true : null,
            }),
        ]).then(() => {
            return newPublicState
        })
    }

    async duplicate(newCreatorId) {
        if (!newCreatorId)
            throw new Error('newCreatorId required for duplicate')
        const newScriptId = utils.createId()

        const newMeta = {
            ...this.meta,
            scriptId: newScriptId,
            creator: newCreatorId,
            public: false,
            parentScriptId: this.meta.scriptId,
            displayName: this.meta.displayName + ' (fork)',
            lastupdate: Date.now(),
        }
        await set(getScriptMetaRef(newScriptId), newMeta)

        const newData = this.data
        await set(getScriptDataRef(newScriptId), newData)

        this.setUserShare(newScriptId)

        return newScriptId
    }
}

export async function getFullScriptObject(id) {
    let [meta, data] = await Promise.all([
        refToPromise(getScriptMetaRef(id)),
        refToPromise(getScriptDataRef(id)),
    ])
    return { meta, data }
}

export async function getPublicList() {
    return new Promise((resolve, reject) => {
        onValue(
            getPublicIDListRef(),
            async (snapshot) => {
                const val = snapshot.val()
                if (val === null) resolve([])

                let results = await Promise.allSettled(
                    Object.keys(val).map((id) =>
                        refToPromise(getScriptMetaRef(id))
                    )
                )

                resolve(
                    results
                        .filter((v) => v.status === 'fulfilled')
                        .map((v) => v.value)
                )
            },
            (err) => {
                reject(err)
            },
            { onlyOnce: true }
        )
    })
}

export async function getMyList(user) {
    if (!user.userRecord) return []

    const { view, edit, share } = user.userRecord
    const scripts = Array.from(
        new Set([
            ...Object.keys(view || {}),
            ...Object.keys(edit || {}),
            ...Object.keys(share || {}),
        ])
    )
    return (
        await Promise.allSettled(
            scripts.map((id) => refToPromise(getScriptMetaRef(id)))
        )
    )
        .filter((v) => v.status === 'fulfilled')
        .map((v) => v.value)
}
