import { l as localforage } from './common/localforage-09af12d1.js';
export { l as __moduleExports, l as default } from './common/localforage-09af12d1.js';
import './common/_commonjsHelpers-4f56b6ba.js';



var INDEXEDDB = localforage.INDEXEDDB;
var LOCALSTORAGE = localforage.LOCALSTORAGE;
var WEBSQL = localforage.WEBSQL;
var _config = localforage._config;
var _dbInfo = localforage._dbInfo;
var _defaultConfig = localforage._defaultConfig;
var _driverSet = localforage._driverSet;
var _initDriver = localforage._initDriver;
var _ready = localforage._ready;
var clear = localforage.clear;
var dropInstance = localforage.dropInstance;
var getItem = localforage.getItem;
var iterate = localforage.iterate;
var key = localforage.key;
var keys = localforage.keys;
var length = localforage.length;
var removeItem = localforage.removeItem;
var setItem = localforage.setItem;
export { INDEXEDDB, LOCALSTORAGE, WEBSQL, _config, _dbInfo, _defaultConfig, _driverSet, _initDriver, _ready, clear, dropInstance, getItem, iterate, key, keys, length, removeItem, setItem };
