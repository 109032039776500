/**
 *
 * Acequia Utils
 *
 *
 *
 */

import { createId, QRious, localforage, webdav } from '../deps.js'
import { debounced } from './debounced.js'
import { Evented } from './evented.js'
import { generateRandomName } from './names.js'

/**
 *
 * return the part of path that
 * after the matching part of an
 * application/acequia.js script
 *
 * @param {} context
 */
function getTrimmedPath(request) {
    return request?.aliasObj?.match || request?.scriptPath
}

export default {
    createId,
    generateRandomName,
    getTrimmedPath,
    localforage,
    debounced,
    Evented,
    QRious,
    webdav,
}
