import * as firebase from './firebase.js'

import { getUserId } from './acequia.js'

const { ref, root, get } = firebase

export function getChannelRef(groupId) {
    const channel = `/groupChannels/${groupId}/`
    return ref(root, channel)
}

export async function amIAMember(groupId) {
    const memberRef = ref(root, `/groups/${groupId}/members/${getUserId()}`)
    try {
        return (await get(memberRef)).val()
    } catch (e) {
        return false
    }
}
