const colors = [
    'Ambar',
    'Beige',
    'Azul',
    'Marron',
    'Carmesi',
    'Cyan',
    'Gris',
    'Verde',
    'Indigo',
    'Magenta',
    'Naranja',
    'Rosado',
    'Purpura',
    'Rojo',
    'Violeta',
    'Blanco',
    'Amarillo',
]

const animals = [
    'Hormiga',
    'Antilope',
    'Mono',
    'Tejon',
    'Murcielago',
    'Oso',
    'Castor',
    'Pájaro',
    'Jabali',
    'Camello',
    'Canario',
    'Gato',
    'Leopardo Cazador',
    'Pollo',
    'Chimpance',
    'Chipmunk',
    'Vaca',
    'Cangrejo',
    'Cocodrilo',
    'Ciervo',
    'Perro',
    'Delfin',
    'Burro',
    'Pato',
    'Aguila',
    'Elefante',
    'Huron',
    'Pescado',
    'Zorro',
    'Rana',
    'Cabra',
    'Hámster',
    'Liebre',
    'Caballo',
    'Canguro',
    'Leopardo',
    'Leon',
    'Lagartija',
    'Topo',
    'Mono',
    'Mula',
    'Ostritch',
    'Nutria',
    'Panda',
    'Loro',
    'Cerdo',
    'Turon',
    'Puerco espin',
    'Conejo',
    'Rata',
    'Rinoceronte',
    'Sello',
    'Oveja',
    'Serpiente',
    'Ardilla',
    'Tapir',
    'Sapo',
    'Tigre',
    'Tortuga',
    'Morsa',
    'Ballena',
    'Lobo',
    'Cebra',
]

export function generateRandomName() {
    const color = colors[Math.floor(Math.random() * colors.length)]
    const anim = animals[Math.floor(Math.random() * animals.length)]
    return `${anim} ${color}`
}
