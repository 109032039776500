/**
 *
 * provides an acequia object
 *
 */

import {
    acequiaStore,
    acequiaReady,
    getUserId,
    getDeviceId,
    getInstanceId,
    getKeys,
} from './acequia/acequia.js'

import utils from './acequia/utils.js'

import { webrtc } from './acequia/webRTC.js'
import { config } from './config.js'

import * as auth from './auth/auth.js'
import * as firebase from './acequia/firebase.js'
import * as groups from './acequia/groups.js'
import * as scripts from './acequia/scripts.js'
import * as tokens from './auth/tokens.js'
import * as user from './acequia/user.js'

// add acequia to window
try {
    if (typeof window !== 'undefined') {
        window.acequia = {
            acequiaReady,
            acequiaStore,
            auth,
            config,
            firebase,
            getUserId,
            getDeviceId,
            getInstanceId,
            getKeys,
            groups,
            scripts,
            tokens,
            webrtc,
            user,
            utils,
                }
    }
} catch (e) {
    console.error('Got Error: ', e)
}

export default {
    acequiaReady,
    acequiaStore,
    auth,
    config,
    firebase,
    getUserId,
    getDeviceId,
    getInstanceId,
    getKeys,
    groups,
    scripts,
    tokens,
    webrtc,
    user,
    utils,
}
