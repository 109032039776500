import { generateRandomName } from './names.js'

import {
    child,
    onValue,
    refToPromise,
    remove,
    rootRef,
    set,
    update,
} from './firebase.js'

import { Evented } from './evented.js'
import { slugify } from '../deps.js'
import { getDeviceId, getUserId, acequiaReady } from './acequia.js'

export function getUserRef(uid) {
    return child(rootRef, `users/${uid}`)
}

export function getHandleRef(handle) {
    return child(rootRef, `handles/${handle}`)
}

export function getAliasesRef(uid) {
    return child(rootRef, `aliases/${uid}`)
}

export function slugifyHandle(handle) {
    return slugify(handle, {
        lower: true,
        trim: false,
        remove: /[@.#$[\]/\\]/g,
    })
}

class UserAccount extends Evented {
    constructor(userId) {
        super()

        this.userId = userId
        this.userRecord = {}
        this.aliases = []

        onValue(getUserRef(this.userId), async (snapshot) => {
            const val = snapshot.val()
            if (val === null) {
                // new user
                const displayName = generateRandomName()
                const handle = slugifyHandle(displayName)
                await set(getUserRef(this.userId), {
                    userId: this.userId,
                    displayName,
                    devices: [getDeviceId()],
                })
                await this.updateHandle(handle)

                return
            }

            this.userRecord = val
            this.fire('update')
        })

        onValue(getAliasesRef(this.userId), (snapshot) => {
            const val = snapshot.val()

            if (val === null && this.aliases.length > 0) {
                this.aliases = []
            } else if (val !== null) {
                this.aliases = val
            }
            this.fire('updatealiases')
        })
    }

    async addAlias(alias, scriptId) {
        this.aliases.push({ alias, scriptId })
        return set(getAliasesRef(this.userId), this.aliases)
    }

    async setDisplayName(newName) {
        return update(getUserRef(this.userId), { displayName: newName })
    }

    async updateHandle(rawHandle) {
        const handle = slugifyHandle(rawHandle)

        if (!handle) throw new Error('handle not legal: ', handle)
        if (this.userRecord?.handle && this.userRecord.handle !== handle)
            remove(getHandleRef(this.userRecord.handle), null)

        return new Promise((resolve, reject) => {
            onValue(
                getHandleRef(handle),
                (snapshot) => {
                    const val = snapshot.val()

                    if (val === this.userRecord.userId) return resolve(handle)
                    if (val !== null) return reject('handle already claimed')

                    set(getHandleRef(handle), this.userRecord.userId).then(
                        () => {
                            return update(getUserRef(this.userRecord.userId), {
                                handle,
                            }).then(() => resolve(handle))
                        }
                    )
                },
                { onlyOnce: true }
            )
        })
    }

    async merge(userId) {
        // transfer userId
    }
}

let userReadyResolve
let userReadyReject
let userReadyPromise = new Promise((resolve, reject) => {
    userReadyResolve = resolve
    userReadyReject = reject
})

export function userReady() {
    return userReadyPromise
}

// Interesting...  wonder what the state of the export is until ready has ocurred
let userAccount, userId, deviceId
acequiaReady()
    .then((ids) => {
        userId = ids.userId
        deviceId = ids.deviceId
        userAccount = new UserAccount(userId)
        console.log('User: ', { userId, deviceId })
        userReadyResolve(userAccount)
    })
    .catch((err) => userReadyReject(err))

export { userAccount }

export async function getAliasLinks(scriptId) {
    const aliases = await refToPromise(getAliasesRef(getUserId()))
    return (aliases || []).filter((alias) => alias.scriptId === scriptId)
}
