/**
 *
 *  Basic Acequia functions
 *
 */

import { config } from '../config.js'

import utils from './utils.js'
import { localforage } from '../deps.js'

import { jose } from '../deps.js'
import { ensurePublicKeyPublished, authReady, signIn } from '../auth/auth.js'

export const acequiaStore = localforage.createInstance({ name: 'acequia' })

// setup acequia environment

async function registerServiceWorker() {
    if ('serviceWorker' in navigator) {
        try {
            const registration = await navigator.serviceWorker.register(
                `${config.build_root}sw.js`,
                {
                    scope: `${config.build_root}`,
                    type: 'module',
                }
            )
            if (registration.installing) {
                console.log('Service worker installing')
            } else if (registration.waiting) {
                console.log('Service worker installed')
            } else if (registration.active) {
                console.log('Service worker active')
            }
        } catch (error) {
            console.error(`Registration failed with ${error}`, error)
        }
    }
}

if (typeof window !== 'undefined') registerServiceWorker()

async function ensureGUID(name) {
    let id = await acequiaStore.getItem(name)
    if (!id) {
        id = await acequiaStore.setItem(name, utils.createId())
    }
    return id
}

async function ensureKeys() {
    let keys = await acequiaStore.getItem('keys')
    if (!keys) {
        keys = await jose.generateKeyPair('PS256', {
            extractable: true,
            keyUsages: ['encrypt', 'decrypt', 'sign', 'verify'],
        })
        keys = await acequiaStore.setItem('keys', keys)
    }

    return keys
}

async function ensureGUIDs() {
    const [deviceId, userId, keys] = await Promise.all([
        ensureGUID('deviceId'),
        ensureGUID('userId'),
        ensureKeys(),
    ])

    instanceId = utils.createId()

    console.log('deviceId: ', deviceId)
    console.log('userId: ', userId)
    console.log('instanceId: ', instanceId)
    // console.log('keys', keys)

    if (typeof window !== 'undefined') {
        window.jose = jose
        window.keys = keys
    }

    return { deviceId, userId, keys }
}

//  Setup deviceId and userId from indexeddb
//  This is an async process so acequia needs a ready() promise
//  To inform scripts when acequia has been set up

let deviceId = null,
    userId = null,
    keys,
    instanceId = null,
    privateJWK = null

let resolveReady
let readyPromise = new Promise((resolve) => {
    resolveReady = resolve
})

function makeReady(status) {
    return resolveReady(status)
}

export function acequiaReady() {
    return readyPromise
}

ensureGUIDs().then(async (guids) => {
    deviceId = guids.deviceId
    userId = guids.userId
    keys = guids.keys

    await ensurePublicKeyPublished(userId, keys)
    authReady().then(() => {
        signIn()
    })
    privateJWK = await jose.exportJWK(keys.privateKey)

    makeReady({ deviceId, userId, instanceId, keys, privateJWK })
})

export function getDeviceId() {
    return deviceId
}

export function getUserId() {
    return userId
}

export function getKeys() {
    return keys
}

export function getInstanceId() {
    return instanceId
}
