export class Evented {
    constructor() {
        this.events = {}
    }

    on(eventName, callback, once = false) {
        if (typeof callback !== 'function') return
        if (!Object.hasOwn(this.events, eventName)) {
            this.events[eventName] = []
        }
        this.events[eventName].push({
            eventName,
            once,
            callback,
        })
    }

    once(eventName, callback) {
        this.on(eventName, callback, true)
    }

    off(eventName, callback) {
        if (Object.hasOwn(this.events, eventName)) {
            if (typeof callback === 'function') {
                this.events[eventName] = this.events[eventName].filter((av) => {
                    if (callback == av.callback) return null
                    return true
                })
            } else {
                delete this.events[eventName]
            }
        }
    }

    fire(eventName, ...argument) {
        let evs = this.events[eventName]
        if (evs) {
            this.events[eventName].forEach((elem) => {
                setTimeout(() => elem.callback(...argument))
                if (elem.once) {
                    this.off(eventName, elem.callback)
                }
            })
        } else {
            console.warn(`${eventName} has no listeners`)
        }
    }

    fireAll(argument) {
        for (var k in this.events) {
            this.fire(k, argument)
        }
    }
}
